.userContainer {
    background-color: #E9F9EE;
    display: flex;
  
    margin-bottom: 80px;
    margin-top: 80px;
    border-radius: 20px;
}
.photoWrapper{
    flex: 1    ;
    padding: 20px;
}
.photoWrapper img {
    width: 100%;
    border-radius: 20px;
}
.dataWrapper {
    flex: 3;
}
.UserTitle {
    font-family: 'Playfair Display';
    font-size: 36px;
}