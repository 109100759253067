.wrapper {
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.headerRight {
  display: flex;

  .headerMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul {
      display: flex;

      li {
        list-style: none;

        a {
          padding: 8px 16px;
          color: black;
          text-decoration: none;
        }
      }
    }
    .headerBtn {
      padding: 16px 32px;
    }
  }
}
