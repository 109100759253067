.Form {
  flex-direction: column;
  display: flex;
  max-width: 800px;
  padding: 0px 0 120px;
  min-height: 100svh;
}

.countContainer {
  display: flex;
  /*justify-content: space-between;*/
  justify-content: flex-end;
  background: white;
  box-shadow: 0px 0px 5px #00000024;
  padding: 10px 20px;
  align-items: center;
  margin-top: 20px;
  border-radius: 20px;
  gap: 20px;
  position: fixed;
  bottom: 35px;
  max-width: 800px;
  width: 100%;
}

.Form .item {
  background-color: #e9f9ee;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 5px 25px;
  align-items: start;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.header {
  text-align: center;
  margin-bottom: 20px;
  padding: 20px;
}
.Problems {
  display: flex;
  flex-wrap: wrap;
}

.itemProblem {
  flex: 1 1 48%;
  margin: 1%;
  max-width: 48%;
}

.titleContainer {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 10px;
}

.resultItem {
  background-color: #e9f9ee;
  border-radius: 20px;
  padding: 24px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 769px) {
  h2 {
    text-align: center;
    margin-top: 40px;
    font-size: 30px;
  }
  .itemProblem {
    flex: 1 1 100%;
    margin: 1%;
    max-width: 100%;
  }
  .resultItem {
    width: 100%;
    margin: 10px;
  }

  .countContainer {
    width: 90%;
  }
}
