.container {
  max-width: 1300px;
  margin: 0 auto;
 }
 section {
  padding: 80px 0;
}
.flex {
  display: flex;
  justify-content: space-evenly;
}
.mainLeft,
.mainRight {
  flex: 1;
}
h1 {
  font-family: 'Playfair Display', serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
h2 {
  text-align: center;
  margin-top: 40px;
}
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

}
.itemsWrapper {
  max-width: 1300px;
  margin: 0 auto;
}

#why .item img {
  height: 124px;
}
span.advText {
  font-size: 18px;
  font-family: 'Playfair Display';
  margin-bottom: 15px;
}
span.experiens {
  background: white;
  padding: 9px;
  font-size: 12px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  border-radius: 10px;
}
#orderSect .container {
  background-color: #E9F9EE;
  border-radius: 20px;
  padding: 40px 10px;
  display: flex;
    flex-direction: column;
    align-items: center;
}

#orderSect .item {
  background: #fff;
  border-radius: 20px;
  position: relative;
  height: 250px;
  flex: 1;
  margin: 10px;
  padding: 20px;
}
.itemNum {
  width: fit-content;
  position: absolute;
  bottom: 25px;
  left: 20px;
}
.itemIcon {
  width: fit-content;
  position: absolute;
  bottom: 25px;
  right: 20px;
}

h3 {
  font-family: 'Playfair Display';
  font-size: 24px;
  font-weight: 500;
}

#insta .container {
  background-color: #E9F9EE;
  border-radius: 20px;
align-items: ;
  display: flex;
  
}
a.linkContainer {
  background: white;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
  width: fit-content;
  border-radius: 10px;
}

#insta .left {
  display: flex;
  flex-direction: column;
 
  justify-content: center;
}
#insta .left h2 {
  text-align: left;
}
#insta .left, #insta .right {
  flex: 1;
  padding: 0px 7%;
}

.postsWrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.itemPost {
  flex: 1;
  padding: 16px;
  background-color: #E9F9EE;
  border-radius: 20px;
}

.itemPost img {
  width: 100%;
  border-radius: 20px;
}