.sliderImg {
    height: 200px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.alice-carousel__prev-btn {
    text-align: left;
    position: absolute;
    top: 78px;
    left: -55px;
}
.alice-carousel__next-btn {
    text-align: right;
    position: absolute;
    top: 78px;
    right: -55px; 
}
.alice-carousel__prev-btn [data-area]::after, .alice-carousel__next-btn [data-area]::after {
    position: relative;
    content: attr(data-area);
    text-transform: capitalize;
    font-size: 50px;
}

.sliderItem {
    background-color: #E9F9EE;
    border-radius: 20px;
    padding: 24px;
    margin: 10px;
    display: flex;
    flex-direction: column;
 
    
}
#psychologists .sliderItem p {
    font-size: 24px;
    font-family: 'Playfair Display';
    line-height: 1;
    margin: 16px 0px;
}
span.price {
    font-size: 15px;
    font-family: 'Playfair Display';
}
.itemBtn {
    cursor: pointer;
    padding: 12px 24px;
    margin-top: 12px;
    width: 100%;
}

.more {
    background-color: white;
}

.catalog {
    background-color: #ffcc00;
    width: fit-content;
}

.catalogContainer {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 35px;
}

.alice-carousel__prev-btn, .alice-carousel__next-btn {

    width:50px;
   
}