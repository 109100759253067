footer {
    background-color:#E9F9EE  ;
    padding: 50px 0;
}
footer .container {
    display: flex;
    justify-content: space-between;
}

footer .widget {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: start;
}